import '@babel/polyfill'
import 'mutationobserver-shim'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueGtag from 'vue-gtag'

axios.defaults.baseURL='https://cave.cs.columbia.edu/'

// Create the Vue app
const app = createApp(App)

app.use(VueGtag, {
  config: { id: 'G-CC5EGRWWF4' },
  pageTrackerScreenview: true, // Optional: Track screenviews (pages) automatically
})

router.afterEach((to) => {
    // Send pageview to Google Analytics
    app.config.globalProperties.$gtag('event', 'page_view', {
        page_path: to.fullPath, // Path of the new page
        page_title: to.meta.title || document.title, // Optional: Set page title
        page_location: window.location.href, // Current URL
    });
});

// Use the router and mount the app
app.use(router).mount('#app')
